.container {
    display: flex;
    flex-direction: column;
}
.centered {
    justify-content: center;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    line-height: 60px;
    background-color: #f5f5f5;
    display: block;
}

.container-footer {
    width: auto;
    max-width: 680px;
    padding: 0 15px;
}