.align {
    text-align: center;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}

td.num {
    margin: 0 !important;
    padding: 0 !important;
}

.iframe-container {
    overflow: hidden;
    /* Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625) */
    padding-top: 56.25%;
    position: relative;
}

.iframe-container iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}